import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import './App.css';

function WalletChecker() {
  const [inputValue, setInputValue] = useState('');
  const [searchResults, setSearchResults] = useState({});
  const [searchPerformed, setSearchPerformed] = useState(false);
  const videoRef = useRef(null);

  const csvFiles = [
    '/csvs/Air Drop A (Confirmed) 15th Feb Starting 9PM EST.csv',
    '/csvs/Air Drop B (Confirmed) 15th Feb Starting 9PM EST.csv',
    '/csvs/Air Drop C (Confirmed) 15th Feb Starting 9PM EST.csv',
    '/csvs/Booster (Confirmed) 13th Feb 1015PM EST (60min window).csv',
    '/csvs/Collab Allowlist (Confirmed) 13th Feb 1015PM EST (60min window).csv',
    '/csvs/Delegate (Confirmed) 13th Feb 1015PM EST (60min window).csv',
    '/csvs/OG Allowlist (Confirmed) 13th Feb 9PM EST (60min window).csv',
    '/csvs/Phase 2 FCFS Allowlist (Confirmed) 13th Feb 1115PM EST (60min window).csv',
    '/csvs/Standard Allowlist (Confirmed) 13th Feb 1015PM EST (60min window).csv'
  ];

  const handleSearch = () => {

    setSearchResults({});
    setSearchPerformed(false);
    let filesProcessed = 0;

    csvFiles.forEach(file => {
      Papa.parse(file, {
        download: true,
        complete: (results) => {
          const found = results.data.some(row => row[0].toLowerCase() === inputValue.toLowerCase() && row[0] !== '');
          setSearchResults(prev => {
            const newResults = { ...prev, [file]: found };
            filesProcessed++;

            if (filesProcessed === csvFiles.length) {
              setSearchPerformed(true); // Set search performed to true after all files are processed
            }

            return newResults;
          });
        },
        error: (error) => {
          console.error(`Error reading file ${file}:`, error.message);
          filesProcessed++;
        }
      });
    });
  };

  const isWalletFound = Object.values(searchResults).some(found => found);

  return (
    <div className='wallet-checker'>
      <div className="input-row">
        <input
          type="text"
          placeholder='Please enter a BTC taproot address...'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className='input-field'
        />
        <button
          onClick={handleSearch}
          className='search-button'
        >
          Search
        </button>
      </div>
      <div className="results-row">
        <video autoPlay loop muted className="background-video" ref={videoRef}>
          <source src="/bits.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="results-container">
          {searchPerformed && !isWalletFound && (
            <div className="no-match-message">
              This wallet is not currently on the allocation list.
            </div>
          )}
          {Object.entries(searchResults).map(([file, found]) => (
            found && (
              <div key={file} className='result'>
                <span>{file.replace('/csvs/', '').replace('.csv', '')}</span>
                <img src='/yes.gif' alt="Checkmark" />
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
}

export default WalletChecker;