import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import WalletChecker from './WalletChecker';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Analytics />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/walletchecker" element={<WalletChecker />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
